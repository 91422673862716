import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../utils/LanguageContext';
import { useMounted } from '../../../utils/useMounted';
import FileInput from '../../common/FileInput/FileInput';
import Input from '../../common/Input/Input';
import Notification from '../../Notification/Notification';
import * as styles from './JobForm.module.scss';

const c = classNames.bind(styles);

const SUCCESS_MESSAGE = {
	fr: `Votre message a bien été envoyé ! Nous reviendrons vers vous dans les plus brefs délais.`,
	en: `Your message has been sent! We'll get back to you as soon as possible.`,
};

const ERROR_MESSAGE = {
	fr: `Une erreur est survenue. Veuillez réessayer dans un instant.`,
	en: `An error has occurred. Please retry in a while.`,
};

function JobForm({ className, titleJob }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						form_fields_require_label {
							en
							fr
						}
						single_job_form_title {
							en
							fr
						}
						form_job_send_label {
							en
							fr
						}
						form_message_placeholder {
							en
							fr
						}
						form_message_label {
							en
							fr
						}
						form_email_placeholder {
							en
							fr
						}
						form_email_label {
							en
							fr
						}
						form_firstname_placeholder {
							en
							fr
						}
						form_firstname_label {
							en
							fr
						}
						form_lastname_placeholder {
							en
							fr
						}
						form_lastname_label {
							en
							fr
						}
					}
				}
			}
		}
	`);
	const strings = query.wp.sp.strings;
	const { isMounted } = useMounted();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [notif, setNotif] = useState(null);

	const lang = language.languageCode === 'FR' ? 'fr' : 'en';

	async function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);
		let data = new FormData();

		data.append('firstname', firstName);
		data.append('lastname', lastName);
		data.append('email', email);
		data.append('message', message);
		data.append('job', titleJob);
		data.append('file', file);

		try {
			const res = await fetch(
				process.env.GATSBY_WORDPRESS_URL + 'wp-json/form/contact',
				{ method: 'POST', body: data },
			);
			const json = await res.json();
			if (json.status === 'error') throw json.error;
			if (!isMounted.current) return;
			setNotif({ message: SUCCESS_MESSAGE[lang], type: 'success' });
			console.log(e.target);
			e.target.reset();
			setFirstName('');
			setLastName('');
			setEmail('');
			setMessage('');
			setFile(null);
		} catch (error) {
			if (!isMounted.current) return;
			setNotif({ message: ERROR_MESSAGE[lang], type: 'error' });
		} finally {
			if (!isMounted.current) return;
			setLoading(false);
		}
	}

	return (
		<section id="form" className={c('wrapper', className)}>
			<h2 className={c('title')}>
				{language.languageCode === 'FR'
					? strings.single_job_form_title.fr
					: strings.single_job_form_title.en}
			</h2>

			<StaticImage
				alt=""
				width={480}
				className={c('titleImg')}
				src="../../../images/sphere-sand-orange.png"
				formats={['auto', 'avif', 'webp']}
			/>

			{notif && <Notification message={notif} ended={() => setNotif(null)} />}

			<form className={c('form')} onSubmit={handleSubmit}>
				<Input
					required
					name="firstname"
					value={firstName}
					label={
						language.languageCode === 'FR'
							? strings.form_firstname_label.fr
							: strings.form_firstname_label.en
					}
					loading={loading}
					setValue={setFirstName}
					placeholder={
						language.languageCode === 'FR'
							? strings.form_firstname_placeholder.fr
							: strings.form_firstname_placeholder.en
					}
					className={c('input', 'firstname')}
				/>

				<Input
					required
					name="lastname"
					value={lastName}
					label={
						language.languageCode === 'FR'
							? strings.form_lastname_label.fr
							: strings.form_lastname_label.en
					}
					loading={loading}
					setValue={setLastName}
					placeholder={
						language.languageCode === 'FR'
							? strings.form_lastname_placeholder.fr
							: strings.form_lastname_placeholder.en
					}
					className={c('input', 'lastname')}
				/>

				<Input
					required
					name="email"
					type="email"
					value={email}
					loading={loading}
					label={
						language.languageCode === 'FR'
							? strings.form_email_label.fr
							: strings.form_email_label.en
					}
					setValue={setEmail}
					placeholder={
						language.languageCode === 'FR'
							? strings.form_email_placeholder.fr
							: strings.form_email_placeholder.en
					}
					className={c('input', 'email')}
				/>

				<Input
					required
					textarea
					name="message"
					label={
						language.languageCode === 'FR'
							? strings.form_message_label.fr
							: strings.form_message_label.en
					}
					value={message}
					loading={loading}
					setValue={setMessage}
					className={c('input', 'message')}
					placeholder={
						language.languageCode === 'FR'
							? strings.form_message_placeholder.fr
							: strings.form_message_placeholder.en
					}
				/>

				<FileInput className={c('fileInput')} uploadFile={setFile} />

				<button type="submit" className={c('submit')}>
					{language.languageCode === 'FR'
						? strings.form_job_send_label.fr
						: strings.form_job_send_label.en}
				</button>

				{/* <p>{error}</p> */}

				<p className={c('legend')}>
					*&#8239;
					{language.languageCode === 'FR'
						? strings.form_fields_require_label.fr
						: strings.form_fields_require_label.en}
				</p>
			</form>
		</section>
	);
}

export default JobForm;
