import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './Dash.module.scss';
import { useInView } from 'react-intersection-observer';

const c = classNames.bind(styles);

function Dash({ className }) {
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 1,
		rootMargin: "-50px",
	});

	return <div className={c('wrapper', className, {inView})} ref={ref}></div>;
}

export default Dash;
