import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect, useRef } from 'react';
import Dash from '../../../components/common/Dash/Dash';
import Picto from "../../../components/common/Picto/Picto";
import Letter from '../../../svg/letter.svg';
import Linkedin from '../../../svg/linkedin.svg';
import { LanguageContext } from '../../../utils/LanguageContext';
import * as styles from './Actions.module.scss';

const c = classNames.bind(styles);

function Actions({ className, href }) {
	const self = useRef(null);
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						single_job_share_label {
							en
							fr
						}
						single_job_apply_label {
							en
							fr
						}
					}
				}
			}
		}
	`)
	const strings = query.wp.sp.strings;

	useEffect(() => {
		if (self.current) {
			self.current.style.top = `calc( 50% - ${self.current.offsetHeight / 2}px )`
		}
	}, [])

	return (
		<aside className={c('wrapper', className)}>
			<div className={c('sticky')} ref={self}>
				<a
					href="#form"
					aria-label={
						language.languageCode === "FR"
							? "Postuler à l'offre d'emploi"
							: "Apply to job offer"
					}
					className={c('anchor')}
				>
					<span className={c('label')}>
						{
							language.languageCode === "FR"
								? strings.single_job_apply_label.fr
								: strings.single_job_apply_label.en
						}
					</span>
					<Picto className={c('picto')}><Letter /></Picto>
				</a>
				<Dash className={c('dash')} />
				<a
					href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}`}
					aria-label={
						language.languageCode === "FR"
							? "Partager sur LinkedIn"
							: "Share on LinkedIn"
					}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className={c('label')}>
						{
							language.languageCode === "FR"
								? strings.single_job_share_label.fr
								: strings.single_job_share_label.en
						}
					</span>
					<Picto className={c('picto')}><Linkedin /></Picto>
				</a>
			</div>
		</aside>
	);
}

export default Actions;
