import classNames from 'classnames/bind';
import React from 'react';
import Dash from '../../common/Dash/Dash';
import * as styles from './Section.module.scss';

const c = classNames.bind(styles);

function Section({ title, text, className }) {
	return (
		<section className={c('wrapper', className)}>
			<h2 className={c('title')}>{title}</h2>

			<div
				className={c('content')}
				dangerouslySetInnerHTML={{ __html: text }}
			/>

			<Dash className={c('dash')} />
		</section>
	);
}

export default Section;
