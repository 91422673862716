import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import Intro from '../../components/Intro/Intro';
import Actions from '../../components/JobPost/Actions/Actions';
import JobForm from '../../components/JobPost/JobForm/JobForm';
import Section from '../../components/JobPost/Section/Section';
import Seo from '../../components/Seo/Seo';
import * as styles from './JobPost.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpJob(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			date(formatString: "DD.MM.yyyy")
			acf: acfSingleJob {
				header {
					title
					description
					bgtype
					popin_video
					video {
						url
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					image {
						url
						title
					}
					image_mobile {
						title
						url
					}
				}
				key_informations {
					location
					contract
				}
				block_text {
					items {
						text
						title
					}
				}
			}
		}
		strings: wp {
			sp {
				strings {
					single_job_share_label {
						en
						fr
					}
					single_job_apply_label {
						en
						fr
					}
				}
			}
		}
	}
`;

export default function JobPost({ data, location }) {
	const { seo, translations, acf, date } = data.page;
	const { header, key_informations, block_text } = acf;
	const title_job =
		header.title +
		' - ' +
		key_informations.contract +
		' - ' +
		key_informations.location;

	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />

			<Intro data={header} />

			<p className={c('contract')}>
				{key_informations.contract}, {key_informations.location}
			</p>

			{/* <p className={c('date')}>{date}</p> */}

			{block_text.items?.map((item, i) => (
				<Section key={i} {...item} className={c('textBlock')} />
			))}

			<Actions href={location.href} />

			<JobForm className={c('form')} titleJob={title_job} />
		</main>
	);
}
