import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useRef, useState } from 'react';
import DownloadSVG from '../../../svg/download.svg';
import { LanguageContext } from '../../../utils/LanguageContext';
import * as styles from './FileInput.module.scss';

const c = classNames.bind(styles);

const MAX_FILE_SIZE = 5000000;

function FileInput({ className, uploadFile }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query MyQuery {
			wp {
				sp {
					strings {
						form_upload_label {
							en
							fr
						}
						form_upload_placeholder {
							en
							fr
						}
						form_upload_size_warning {
							en
							fr
						}
					}
				}
			}
		}
	`)
	const strings = query.wp.sp.strings;

	const [fileName, setFileName] = useState(null);
	const [fileSize, setFileSize] = useState(null);

	const inputEl = useRef(null)
	function handleChange() {
		let size = inputEl.current.files[0].size;
		if (size > MAX_FILE_SIZE) {
			inputEl.current.value = "";
			let msg = language.languageCode === "FR"
				? strings.form_upload_size_warning.fr
				: strings.form_upload_size_warning.en;
			setFileSize(`${msg} (max ${MAX_FILE_SIZE / 1000000}MB)`);
		} else {
			setFileName(inputEl.current.files[0].name);
			setFileSize(Math.round(size / 100000) / 10 + "MB");
			uploadFile(inputEl.current.files[0]);
		}
	}
	return (
		<label className={c('wrapper', className)}>
			<DownloadSVG className={c('svg')} />
			<div className={c('texts')}>
				<p className={c('label')}>
					{
						fileName
							? fileName
							: language.languageCode === "FR"
								? strings.form_upload_label.fr
								: strings.form_upload_label.en
					}*
				</p>
				<p className={c('type')}>
					{
						fileSize
							? fileSize
							: language.languageCode === "FR"
								? strings.form_upload_placeholder.fr
								: strings.form_upload_placeholder.en
					}
				</p>
			</div>
			<input
				type="file"
				accept=".pdf"
				onChange={handleChange}
				ref={inputEl}
				className={c('input')}
				required />
		</label>
	);
}

export default FileInput;
