import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './Notification.module.scss';

const c = classNames.bind(styles);

function Notification({ className, message, ended }) {
	const status = message.type;
	
	setTimeout(() => {
		ended();
	}, 5000);

	return (
		<span className={c('wrapper', className, status )}>
            <p className={c('text')}>{message.message}</p>
		</span>
	);
}

export default Notification;
