import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './Input.module.scss';

const c = classNames.bind(styles);

function Input({
	type,
	name,
	title,
	value,
	label,
	pattern,
	loading,
	setValue,
	required,
	className,
	placeholder,
	textarea = false,
}) {
	const Tag = textarea ? 'textarea' : 'input';
	let props = { name, title, value, required, placeholder };
	if (!textarea) props = { ...props, type, pattern };

	return (
		<label className={c('wrapper', { loading }, className)}>
			<p className={c('label')}>{label + (required ? ' *' : '')}</p>

			<Tag
				{...props}
				readOnly={loading}
				className={styles.input}
				onChange={(e) => setValue(e.target.value)}
			/>

			{!textarea && (
				<>
					<span className={c('error')} title={title}>
						!
					</span>

					<span className={c('line')} />
				</>
			)}
		</label>
	);
}

export default Input;
