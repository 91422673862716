// extracted by mini-css-extract-plugin
export var email = "JobForm-module--email--BGMUu";
export var fileInput = "JobForm-module--fileInput--kvNKw";
export var firstname = "JobForm-module--firstname---hbgc";
export var form = "JobForm-module--form--oJq7x";
export var input = "JobForm-module--input--pJMfU";
export var lastname = "JobForm-module--lastname--JrqAy";
export var legend = "JobForm-module--legend--OUWmD";
export var message = "JobForm-module--message--upCyX";
export var submit = "JobForm-module--submit--4A18r";
export var title = "JobForm-module--title--1yL0u";
export var titleImg = "JobForm-module--titleImg--feCKj";
export var wrapper = "JobForm-module--wrapper--pnlDU";